.vedjegyfieldset {
    border: 1px solid #ced4da;
    border-radius: 4px;
    // margin-left: 0.5rem;
    margin-left: unset !important;
    margin-top: unset !important;
    margin-right: unset !important;
    margin-bottom: 0.5rem;
    legend {
        font-weight: 600;
    }
}