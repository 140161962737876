@import "colors.scss";

.gyik-page {

    .gyik-short-summary {
        color: $primaryMildColor;
        max-width: 600px;
        margin: auto;
        text-align: center;
    }
    
    .gyik-answer {
        color: $primaryMildColor;
    }
}

.accessibility .gyik-page {
    
    .page-header {
        background: $accessibilityPrimaryColor;
        color: $accessibilitySecondaryColor;
    }

    .gyik-short-summary, .gyik-answer {
        color: $accessibilitySecondaryColor;
    }
}
