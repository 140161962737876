@import "colors";
@import "settings";

body {
    margin: 0;
    font-family: var(--font-family);
}

.accessibility {
    background: $accessibilityPrimaryColor;
    color: $accessibilitySecondaryColor;

    .content-block {

        &.separator-image {
            display: none;

        }
        .block {
            color: $accessibilitySecondaryColor;
        }
    }

    button.lp-button {
        background: $accessibilitySecondaryColor;
        color: $accessibilityPrimaryColor;
        border: 3px solid $accessibilityPrimaryColor;

        &:hover {
            background: $accessibilityPrimaryColor;
            border-color: $accessibilitySecondaryColor;
        } 

        &:focus {
            box-shadow: none;
            background: $accessibilityPrimaryColor;
            border-color: $accessibilitySecondaryColor;
            color: $accessibilitySecondaryColor;  
        }
    }

    .page-header {
        background: $accessibilityPrimaryColor;

        .heading {
            color: $accessibilitySecondaryColor;
            background: unset;
        }
    }

    .footer-container {
        background: $accessibilityPrimaryColor;
    }

    content-block:nth-of-type(2n + 0) .content-block {
        background: $accessibilitySecondaryColor;
        color: $accessibilityPrimaryColor;

        .vedjegy-carousel-item {
            border: 1px solid $accessibilityPrimaryColor !important;
            &:focus{
                box-shadow: 0 0 0 0.25rem $accessibilityPrimaryColor;
            }

            .vedjegy-carousel-item-title {
                color: $accessibilityPrimaryColor;
            }
    
            .vedjegy-carousel-item-summary {
                color: $accessibilityPrimaryColor;
            }
        }

        .content-block-content {
            .gyik-answer {
                color: $accessibilityPrimaryColor;
            }
        }

        .block {
            color: $accessibilityPrimaryColor;
        }
    }

    .vedjegy-carousel-item {
        .vedjegy-carousel-item-title {
            color: $accessibilitySecondaryColor;
        }

        &:focus{
            box-shadow: 0 0 0 0.25rem $accessibilitySecondaryColor;
        }

        .vedjegy-carousel-item-summary {
            color: $accessibilitySecondaryColor;
        }
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background: $accessibilityPrimaryColor;
    }

    .p-link:focus {
        box-shadow: none;
    }

    @media screen and (max-width: 960px) {
        .footer-sz2020-logo {
            background-color: black;
        }
    }
}

.landing-page {
    position: relative;
    min-height: 100vh;

    .landing-page-top {
        padding-bottom: 100px;
    }
}

.center-block {
    max-width: $breakpointMedium;
    margin: auto;
}

.content-block {
    padding: 1rem 0 2rem;

    .block {
        max-width: $breakpointMedium;
        margin: auto;
        color: $primaryMildColor;

        .content-block-header {
            text-align: center;
            font-size: 3rem;
            font-weight: 200;
            margin-bottom: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .toggler {
                display: none;
            }

            .heading-text {
                width: 100%;
            }
            
            @media screen and (max-width: 960px) {
                .heading-text {
                    font-size: 1.5rem;
                }
            }
        }

        .content-block-content {
            .sub-heading {
                text-align: center;
                font-size: 2rem;
                font-weight: 200;
                margin-bottom: 1rem;
            }
        }
    }

    .content-button-holder {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }

    &.closeable {
        padding: 1.5rem 0;
        .block {
            .content-block-header {
                font-weight: 600;
                margin-bottom: 0;
                cursor: pointer;
                font-size: 1.5rem;

                .toggler {
                    display: flex;
                    margin-left: 1rem;
                    align-self: flex-start;
                    margin-top:1rem;
                    margin-right:2rem;
                }
            }

            .content-block-content {
                height: 0;
                overflow: hidden;
            }
        }

        &.content-block-opened .content-block-content {
            height: auto;
            overflow: auto;
        }
    }
}

button.lp-button {
    background: $primaryColor;
    border-radius: 10px;
    height: 3.5rem;
    border: none;
    min-width: 150px;

    &:hover {
        background: $primaryMildColor;
    }
}

.divider > .content-block {
    height: 15vw;
}
.divider.divider-photo > .content-block {
    background-position: center;
}

content-block:nth-of-type(2n + 0) .content-block {
    background-color: $primaryLightColor;

    .vedjegy-carousel-item {
        border-color: #e0e0e0;
    }
}

@media screen and (max-width: 920px) {
    .landing-page {
        .vedjegy-carousel-item {
            width: 200px;
            .vedjegy-carousel-item-title {
                font-size: 1.5rem;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .landing-page {
        .vedjegy-carousel-item {
            width: 192px;
            .vedjegy-carousel-item-title {
                font-size: 1.5rem;
            }

            .header-image-container {
                height: 140px;
            }
        }
    }
}

.vedjegy-carousel-item {
    padding: 1rem;
    border: 1px solid $primaryLightColor;

    width: 250px;    
    margin: 0.5rem;
    text-align: center;
    .header-image-container {
        padding: 2rem 0;
        height: 180px;
        width: auto;
        .vedjegy-carousel-item-header-image {
            max-width:100%;
            max-height: 100%;
            margin: auto;
            display: block;
        }
    }

    .vedjegy-carousel-item-title {
        color: $primaryMildColor;
        font-size: 2rem;
        word-wrap:break-word;
        padding: 1rem 0;
    }

    .vedjegy-carousel-item-summary {
        color: $primaryMildColor;
    }

    .vedjegy-carousel-item-button-container {
        padding-top: 0.5rem;
        display: flex;
        justify-content: center;
    }
}

.link-item {
    cursor: pointer;
}

page-header .page-header {
    height: 240px;
    background-image: url("/assets/images/section_bg.svg");
    background-size: cover;
    background-position: 50% 38%;

    .page-title {
        font-size: 4rem;
        color: $primaryDarkColor;
        display: flex;
        height: 100%;
    }

    .heading{
        text-align: center;
        font-weight: 700;
        max-width: 750px;
        margin: auto;
        line-height: 4rem;
        font-size: 4rem;
        background: #dff0f8;
    }
    
    @media screen and (max-width: 960px) {
        .heading{
            line-height: 2rem;
            font-size: 1.5rem;
        }
    }
}

.landing-page {
    .pi-sort-alt:before {
        content: "\e930";
    }

    .pi-sort-amount-up-alt:before {
        content: "\e9a2";
    }

    .pi-sort-amount-down:before {
        content: "\e9a0";
    }

    .p-datatable {
        .p-datatable-thead > tr.table-names > th {
            background: $primaryMilderColor;
            color: $primaryColor;
        }

        .p-inputtext {
            padding: 0.15rem 0.25rem;
            border-color: $primaryColor;
        }

        .p-sortable-column .p-sortable-column-icon {
            color: $primaryColor;
        }
    }

    @media screen and (max-width: 680px) {
        
        .p-toast {
            width: 100%;
        }

        .p-toast-top-right {
            right: 0;
        }
    }

    .justifyText {
        text-align: justify;
    }
}

.sort-icon {
    float: right;
}

.sz2020-logo {
    position: fixed;
    bottom: -4px;
    right: 0;
    margin: 0;
    img {
        height: 180px;
    }
}

.lp-form {
    margin-top: 1rem;
    margin-bottom: 1rem;

    input,
    textarea {
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 4px;
        width: 100%;
    }

    .ui-message.ui-messages-error {
        color: $errorColor;
    }

    vedjegy-form-group .form-group {
        padding-bottom: 0.5rem;
        > div {
            padding: 0.4rem 0.4rem 0;
        }

        label {
            font-weight: 600;
        }
    }

    .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 1rem;
    }
}

label.required-label::after {
    content: " *";
    color: $errorColor;
}

@media screen and (max-width: 960px) {
    .content-block {
        padding: 1rem 0;

        .block {
            padding: 0 0.5rem;

            .content-block-header {
                font-size: 2rem;
            }

            .content-block-content {
                .sub-heading {
                    font-size: 1.5rem;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .content-button-holder {
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
    }

    page-header .page-header {
        height: 100px;

        .page-title {
            font-size: 2rem;
            color: $primaryDarkColor;
            display: flex;
            height: 100%;
        }
    }

    .landing-page {
        .landing-page-top {
            padding-bottom: 0;
        }
    }

    .vedjegy-carousel-item {
        .header-image-container {
            padding: 0.5rem 0 0;

            .vedjegy-carousel-item-header-image {
                height: 120px;
            }
        }
    }
    .sz2020-logo {
        img {
            display: none;
        }
    }
    .footer-sz2020-logo {
        text-align: right;
        background-color: #123274;
        
        img {
            width: 200px;
            height: unset;
            vertical-align: bottom;
        }
    }

    button.lp-button {
        height: 3rem;
        min-width: auto;
    }
}
@media screen and (max-width: 680px) {
    img {
        height: 80px;
    }

    .p-toast-top-right {
        right: 0;
    }
}

@media screen and (min-width: 960px) {
    .footer-sz2020-logo {
        display: none;
    }
}