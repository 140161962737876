@import "colors.scss";

.home-page {
    .page-header {
        height: 350px;
        background-image: url("/assets/images/home-header.svg");
        background-size: cover;
        background-position: bottom;
        //-webkit-text-stroke: 0.3px $primaryColor;
        color: #fff;

        .page-title {
            padding-top: 3rem;
            font-size: 4rem;
            letter-spacing: -2px;
            text-align: -moz-center;
            text-align: -webkit-center;
        }

        .page-subtitle {
            padding-top: 0.5rem;
            font-size: 2rem;
            text-align: -moz-center;
            text-align: -webkit-center;
        }

        .headertext {
            background-color: $primaryDarkColor;
            width: fit-content;
            padding-right: 1rem;
            padding-left: 1rem;
            display: unset;
            margin-block-start: unset;
            margin-block-end: unset;
            margin-inline-start: unset;
            margin-inline-end: unset;
            font-size: 4rem;
        }
        .headersubtext {
            font-size: 2rem;
        }
    }

    .workflow-image-container {
        background-color: white;
        padding: 1.5rem;
        border: 2px solid #aaa;
    }
}

.accessibility .home-page {
    .page-header {
        background: $accessibilityPrimaryColor;
    }

    .workflow-image-container img {
        filter: grayscale(100%) contrast(150%);
    }

    .headertext {
        background: $accessibilityPrimaryColor;
    }
}

@media screen and (max-width: 960px) {
    .home-page {
        .page-header {
            height: 140px;

            .page-title {
                padding: 1rem 1rem 0;
                font-size: 2.5rem;
                text-align: -moz-center;
                text-align: -webkit-center;
            }

            .page-subtitle {
                padding-top: 0.2rem;
                font-size: 1.3rem;
                text-align: -moz-center;
                text-align: -webkit-center;
            }

            .headertext {
                width: fit-content;
                padding-right: 0.2rem;
                padding-left: 0.2rem;
                font-size: 2rem;
            }
            .headersubtext {
                font-size: 1.5rem;
            }
    
        }
    }
}

@media screen and (max-width: 400px) {
    .home-page {
        .page-header {
            .page-title {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .home-page {
        .page-header {
            .headertext {
                font-size: 1.5rem;
            }
            .headersubtext {
                font-size: 1rem;
            }    
        }
    }
}

