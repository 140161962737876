@import "colors.scss";

.landing-page.accessibility app-menu .menu-container {
    background: $accessibilityPrimaryColor;

    .p-menubar {
        background: $accessibilityPrimaryColor;

        .p-button:focus {
            box-shadow: 0 0 0 0.1rem $accessibilitySecondaryColor;
        }

        .p-menubar-root-list .p-menuitem>.p-menuitem-link {

            &:focus {
                box-shadow: 0 0 0 0.1rem $accessibilitySecondaryColor;
            }

            &:hover {
                background-color: $accessibilitySecondaryColor;

                .p-menuitem-text,
                .p-submenu-icon {
                    color: $accessibilityPrimaryColor;
                }
            }
        }

        .p-submenu-list .p-menuitem>.p-menuitem-link {

            &:focus {
                box-shadow: 0 0 0 0.1rem $accessibilityPrimaryColor;
            }

            &:hover {
                background-color: $accessibilitySecondaryColor;

                .p-menuitem-text,
                .p-submenu-icon {
                    color: $accessibilityPrimaryColor;
                }
            }
        }

        .p-menubar-root-list .p-menuitem.p-menuitem-active>.p-menuitem-link {
            background-color: $accessibilityPrimaryColor;
            //color: $accessibilityPrimaryColor;

            .p-menuitem-text,
            .p-submenu-icon {
                color: $accessibilitySecondaryColor;
            }
        }

        .p-menuitem-active .p-submenu-list {
            background-color: $accessibilitySecondaryColor;

            .p-menuitem-active {
                .p-menuitem-link {

                    .p-menuitem-text {
                        color: $accessibilitySecondaryColor;
                    }
                }

                .p-menuitem {
                    .p-menuitem-text {
                        color: $accessibilityPrimaryColor;
                    }

                    &.p-menuitem-active {
                        .p-menuitem-link {
                            .p-menuitem-text {
                                color: $accessibilitySecondaryColor;
                            }
                        }
                    }
                }
            }

            .p-menuitem-link {

                .p-menuitem-text {
                    color: $accessibilityPrimaryColor;
                }
            }
        }

        .p-menubar-end {
            .p-button {
                background-color: transparent;
                border: none;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .accessibility app-menu .menu-container {
        .p-menubar {
            .p-menubar-root-list {
                background-color: $accessibilityPrimaryColor;
            }

            .p-menubar-button:focus {
                box-shadow: 0 0 0 0.2rem $accessibilitySecondaryColor;
            }
        }
    }
}

app-menu .menu-container {
    background-color: $primaryColor;
    height: 100px;
    position: sticky;
    position: -webkit-sticky; // Needed for Safari browsers.
    z-index: 10000; // This prevents elements to be seen through the toolbar.
    top: 0; // Required for "position: sticky" to take effect.



    .p-menubar {
        margin: auto;
        padding-top: 15px;
        max-width: $brakepointLarge;
        background-color: $primaryColor;
        color: #fff;
        border-radius: 0;
        height: 100%;

        .header-logo {
            padding-bottom: 3px;
            color: #fff;
        }

        .p-menubar-root-list .p-menuitem>.p-menuitem-content>.p-menuitem-link {

            .p-menuitem-text,
            .p-submenu-icon {
                color: #fff;
            }

            &:hover {
                background-color: $primaryColor;

                .p-menuitem-text,
                .p-submenu-icon {
                    color: $primaryLightColor;
                }
            }

            &:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
            }

            &.p-menuitem-link-active {
                background-color: $primaryColor;
            }
        }

        .p-menubar-root-list .p-menuitem.p-menuitem-active>.p-menuitem-content>.p-menuitem-link {
            background-color: $primaryColor;

            .p-menuitem-text,
            .p-submenu-icon {
                color: $primaryMildColor;
            }

            &:hover {

                .p-menuitem-text,
                .p-submenu-icon {
                    color: $primaryLightColor;
                }
            }
        }

        .p-submenu-list {
            background-color: $primaryColor;
        }

        .p-menubar-end {
            .p-button {
                background-color: transparent;
                border: none;

                .p-button-icon {
                    display: none;
                }
            }
        }

        .accessibility-link {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 960px) {
    app-menu .menu-container {
        height: auto;

        .p-menubar {
            .p-menubar-root-list {
                background-color: $primaryColor;
                max-height: 100vh;
                overflow: scroll;
            }


            .p-menubar-button {
                i,
                barsicon {
                    color: #fff;
                }
            }

            .p-menubar-start {
                order: 1;
                margin: auto;

                .header-logo {
                    margin: 0;
                    padding: 0;
                    height: 25px;
                }
            }

            .p-menubar-end {
                margin-left: 0;
                order: 2;

                .p-button {
                    padding: 0.5rem;

                    .p-button-icon {
                        display: inherit;
                    }

                    .p-button-label {
                        display: none;
                    }
                }
            }

            .accessibility-link {
                img {
                    max-height: 1.5rem;
                }
            }
        }
    }
}