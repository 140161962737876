@import "colors.scss";

.accessibility .login-page {
    .login-container .login-item {
        .item-title {
            color: $accessibilitySecondaryColor;
        }
        .item-summary {
            color: $accessibilitySecondaryColor;
        }
    }

    .register-container .register-item {
        background-color: $accessibilityPrimaryColor;
        .item-title {
            color: $accessibilitySecondaryColor;
        }
        .item-summary {
            color: $accessibilitySecondaryColor;
        }
    }
}

.login-page {
    .login-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .login-item {
            width: 200px;
            margin: 1rem;
            padding: 1rem;
            border: 1px solid $primaryLightColor;

            .header-image-container {
                padding: 1.5rem 0;

                .item-header-image {
                    width: 75%;
                    margin: auto;
                    display: block;
                }
            }

            .item-title {
                color: $primaryMildColor;
                font-size: 2rem;
                padding: 1rem 0;
            }

            .item-summary {
                color: $primaryMildColor;
            }

            .item-button-container {
                padding-top: 1rem;
                display: flex;
                justify-content: center;
            }
        }
    }

    .register-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .register-item {
            width: 300px;
            margin: 1rem;
            padding: 1rem;
            background: #fff;
            border: 1px solid $primaryLightColor;

            .header-image-container {
                padding: 1.5rem 0;

                .item-header-image {
                    max-width: 75%;
                    margin: auto;
                    display: block;
                }
            }

            .item-title {
                color: $primaryMildColor;
                font-size: 1.5rem;
                padding: 1rem 0;
                text-align: center;
            }

            .item-summary {
                min-height: 3rem;
                color: $primaryMildColor;
                text-align: center;
            }

            .item-button-container {
                padding-top: 1rem;
                display: flex;
                justify-content: center;
            }
        }
    }

}

@media screen and (max-height: 750px) {
    .login-page {

        .login-container,
        .register-container {

            .login-item,
            .register-item {
                .header-image-container {
                    padding: 0;
                }
            }
        }
    }
}
