@import "colors.scss";

.vedjegy-page {
    .vedjegy-image-container {
        text-align: center;
        img {
            height: 200px;
        }
    }

    .vedjegy-summary-container {
        padding: 2rem 15rem 1rem;
        text-align: center;
        color: $primaryMildColor;
    }
}

.accessibility .vedjegy-page .vedjegy-summary-container {
    color: $accessibilitySecondaryColor;
}

@media screen and (max-width: 960px) {

    .vedjegy-page {
        .vedjegy-summary-container {
            padding: 0 1rem;
        }
    }
}
