@import "colors.scss";

.landing-page.accessibility .megszerzett-vedjegyek-page {

    content-block:nth-of-type(2n + 0) .content-block {
        background-color: $accessibilityPrimaryColor;
    }

    .secondary-header {
        color: $accessibilitySecondaryColor;
    }

    .p-datatable .p-datatable-thead {

        .table-names>th {
            background: $accessibilitySecondaryColor;
            color: $accessibilityPrimaryColor;
            box-shadow: none;

            i {
                color: $accessibilityPrimaryColor;
            }
        }

        .table-filters .p-inputtext:enabled:focus {
            box-shadow: none;
            border-color: $accessibilityPrimaryColor;
        }
    }

}

.megszerzett-vedjegyek-page {
    .secondary-header {
        text-align: center;
        font-weight: 700;
        max-width: 750px;
        margin: auto;
        line-height: 4rem;
        font-size: 4rem;
        padding-bottom: 3rem;
        color: $primaryDarkColor;
    }
}

@media screen and (max-width:960px) {
    .megszerzett-vedjegyek-page {
        .secondary-header {
            font-size: 2rem;
            padding-bottom: 1rem;
        }
    }
}