.sitemapcontainer {
    margin-left: 30px;
    margin-top: 30px;
    .sitemapelement {
        margin-bottom: 3px;
    }
}

.accessibility
{
    .sitemapcontainer {
        .sitemapelement {
           a{
            color: white;
           }
        }
    }
}