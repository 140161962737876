$primaryColor: #123274;
$primaryDarkColor: #11306e;
$primaryMildColor: #246DAE;
$primaryMilderColor: #b4c9f3;
$primaryLightColor: #e4f1fd;
$grayBackgroundColor: #eeeeee;

$accessibilityPrimaryColor: #000000;
$accessibilitySecondaryColor: #ffffff;

$errorColor: #e62a10;

$linkColor: #0A72C7;