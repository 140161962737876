.accessibility .footer-container {
    .p-button:focus {
        box-shadow: 0 0 0 0.1rem $accessibilitySecondaryColor;
    }

    .p-button:hover {
        box-shadow: 0 0 0 0.1rem $accessibilitySecondaryColor;
    }
}

.footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;

    background-color: $primaryColor;
    color: #fff;
    height: 100px;

    .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        max-width: 1300px;

        .footer-content-left {
            display: flex;
            align-items: center;
            vertical-align: middle;

            div {
                margin-right: 1rem;
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .p-button,
        .p-button:hover,
        .p-button:focus {
            background-color: transparent;
            border: none;
        }
    }
}

@media screen and (max-width: 960px) {
    .footer-container {
        height: auto;
        position: initial;

        .footer-content {
            flex-direction: column;
            justify-content: center;

            .footer-content-left {
                flex-direction: column;
                justify-content: center;
                padding-top: 0.5rem;

                .footer-menu-item {
                    order: -1;
                }

                .footer-logo {
                    height: 2rem;
                    margin-bottom: 0.5rem;
                }

                >div {
                    margin-right: 0;
                    margin-bottom: 0.2rem;
                }
            }
        }
    }
}